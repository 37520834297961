import { Typography } from "@mui/material";
import { XTypographyProps } from "./types";
import { useModifiedStyleForWeb } from "shared/theme";
import { useTheme } from "shared/hooks/use-theme";

export function XTypography({ children, href, ...props }: XTypographyProps) {
  const theme = useTheme();
  const style = useModifiedStyleForWeb(props.style);
  return (
    <Typography
      component="span"
      {...props}
      style={style}
      color={
        props.color === "textCharcoal"
          ? theme.palette.text.charcoal
          : props.color
      }
    >
      {children}
    </Typography>
  );
}
