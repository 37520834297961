import { TSDateYMDString } from "./datetime";
import { SalesforceAccount } from "./salesforce-account";
import { TaskTypeInfo } from "../components/advisor-dashboard/account-overview/create-task-dialog";

export type SalesforceTask = {
  AccountTransferStatus__c: AccountTransferStatus__c;
  Id: string;
  Subject?: string;
  Description?: string;
  Task_Description__c?: string;
  OwnerId: string;
  IsReadyForClient__c: boolean;
  Notify_client_upon_creation__c: boolean;
  CreatedById: string;
  Status: SalesforceTaskStatus;
  XLR8CS__Category__c?: XLR8CS__Category__c;
  XLR8CS__Sub_Category_Task__c?: XLR8CS__Sub_Category_Task__c;
  Presentation_Slides__c?: string;
  LastModifiedDate: TSDateYMDString;
  Presentation_Link__c?: string;
  Investment_Model_Recommendation__c?: string;
  Investment_Proposal_Generation_Source__c?: string;
  Automatically_Load_Investments_To_eMoney__c?: string;
  ActivityDate?: TSDateYMDString;
  CreatedDate: TSDateYMDString;
  Savvy_Task_URL__c?: string;
  Savvy_Task_ID__c?: string;
  What?: SalesforceAccount;
  WhatId?: string;
  SendClientEmailReminders__c: boolean;
  Proposal_Account_Statements_Folder__c?: string;
  WhoId: string;
  __typename: string;
  Request_Type__c: string;
};

export enum AccountTransferStatus__c {
  PendingClientDocuSignSignature = "PendingClientDocuSignSignature",
  PendingAccountCreationAndTransfer = "PendingAccountCreationAndTransfer",
  TransferPartiallyComplete = "TransferPartiallyComplete",
  AccountCreationAndTransferComplete = "AccountCreationAndTransferComplete",
}

export enum XLR8CS__Category__c {
  ClientOperations = "Client Operations",
  ClientServices = "Client Services",
  SelfReminder = "Self Reminder",
  InternalOperations = "Internal Operations",
  Personal = "Personal",
  OpsTask = "Ops Task",
  ClientTask = "Client Task",
}

export enum XLR8CS__Sub_Category_Task__c {
  ClientIntake = "Client: Intake",
  ClientOnboarding = "Client: Onboarding",
  ClientPreReviewCheckIn = "Client: Pre-Review Check In",
  ClientFileUpload = "Client: File Upload",
  ClientOther = "Client: Other",
  ClientAccountConfirmation = "Client: Account Confirmation",
  SelfReminder = "Self Reminder",
  Other = "Other",
  //TODO: [Ted] deprecate below after new MM is set up, also need to deprecate "Money movement"
  MoneyMovement = "Ops: Money movement",
  NewMoneyMovement = "Ops: Money Movement Request",
  Trading = "Ops: Trading Request",
  AccountMaintenance = "Account maintenance",
  ClientInfoChange = "Client info change",
  FinnancialPlanning = "Financial planning",
  InvestmentManagementDirectIndexing = "Investment management: Direct indexing",
  InvestmentManagementSWIM = "Ops: Swim Request",
  ClientReviewPresentation = "Client review presentation",
  InvestmentProposalPresentation = "Investment proposal presentation",
}

export enum SalesforceTaskStatus {
  NotStarted = "Not Started",
  InProgress = "In Progress",
  WaitingOnSomeoneElse = "Waiting on someone else",
  Deferred = "Deferred",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export const CLIENT_INTAKE_TASK: TaskTypeInfo = {
  name: "Intake",
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.ClientIntake,
  description:
    "Send prospects a questionnaire to collect statements and learn more about them",
};

export const CLIENT_ACCOUNT_CONFIRMATION_TASK: TaskTypeInfo = {
  name: "Account confirmation",
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.ClientAccountConfirmation,
  description: "Obtain necessary information from client before onboarding",
};

export const CLIENT_ONBOARDING_TASK: TaskTypeInfo = {
  name: "Onboarding",
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.ClientOnboarding,
  description:
    "Send clients the IAA and account opening documents to be signed",
};

export const CLIENT_PRE_REVIEW_CHECK_IN_TASK: TaskTypeInfo = {
  name: "Pre-review check in",
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.ClientPreReviewCheckIn,
  description: "Collect up-to-date information in prep for a financial review",
};

export const CLIENT_FILE_UPLOAD_TASK: TaskTypeInfo = {
  name: "File upload",
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.ClientFileUpload,
  description: "Ask a client to upload files to their Box account",
};

export const MONEY_MOVEMENT_TASK: TaskTypeInfo = {
  name: XLR8CS__Sub_Category_Task__c.MoneyMovement,
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.MoneyMovement,
  customName: "Money movement",
  description: "Make inbound or outbound transactions on a client account",
  flowDescription: "Make inbound or outbound transactions on a client account",
};

export const TRADING_TASK: TaskTypeInfo = {
  name: XLR8CS__Sub_Category_Task__c.Trading,
  customName: "Trading",
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.Trading,
  description: "Buy and sell multiple holdings for a client portfolio",
};

export const ACCOUNT_MAINTENANCE_TASK: TaskTypeInfo = {
  name: XLR8CS__Sub_Category_Task__c.AccountMaintenance,
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.AccountMaintenance,
  description: "Open, close, move or consolidate an account",
  flowDescription:
    "Open a new account or close, move or consolidate an existing account for your client",
};

export const INVESTMENT_MANAGEMENT_DIRECT_INDEXING_TASK: TaskTypeInfo = {
  name: XLR8CS__Sub_Category_Task__c.InvestmentManagementDirectIndexing,
  customName: "Direct Indexing",
  salesforceSubCategory:
    XLR8CS__Sub_Category_Task__c.InvestmentManagementDirectIndexing,
  description: "Set up or change a direct index account",
};

export const INVESTMENT_MANAGEMENT_SWIM_TASK: TaskTypeInfo = {
  name: XLR8CS__Sub_Category_Task__c.InvestmentManagementSWIM,
  customName: "Savvy Wealth Investment Management (SWIM)",
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.InvestmentManagementSWIM,
  description:
    "Enroll a client into the Savvy Wealth Investment Management Program",
};

export const INTERNAL_CLIENT_REVIEW_PRESENTATION_TASK: TaskTypeInfo = {
  name: XLR8CS__Sub_Category_Task__c.ClientReviewPresentation,
  customName: "Client review presentation",
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.ClientReviewPresentation,
  description: "Request a Savvy branded deck for client reviews",
};

export const INVESTMENT_PROPOSAL_PRESENTATION_TASK: TaskTypeInfo = {
  name: XLR8CS__Sub_Category_Task__c.InvestmentProposalPresentation,
  customName: "Investment proposal presentation",
  salesforceSubCategory:
    XLR8CS__Sub_Category_Task__c.InvestmentProposalPresentation,
  description: "Request a Savvy branded deck for a client or prospect proposal",
};

export const INTERNAL_CLIENT_INFO_CHANGE_TASK: TaskTypeInfo = {
  name: XLR8CS__Sub_Category_Task__c.ClientInfoChange,
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.ClientInfoChange,
  description:
    "Update information across platforms, e.g. new address or last name",
  flowDescription:
    "When client information changes, for example a new address or last name, the Savvy Client Services team will ensure that client information is updated across all platforms including Schwab and eMoney.",
};

export const FINNANCIAL_PLANNING_TASK: TaskTypeInfo = {
  name: XLR8CS__Sub_Category_Task__c.FinnancialPlanning,
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.FinnancialPlanning,
  description: "Request financial planning tool-related support",
};

export const INTERNAL_OTHER_TASK: TaskTypeInfo = {
  name: XLR8CS__Sub_Category_Task__c.Other,
  salesforceSubCategory: XLR8CS__Sub_Category_Task__c.Other,
  description: "Anything else for the Client Services team",
  flowDescription:
    "For example, ask for a report to be generated, contact a client about a topic, resolve an issue with Schwab, organize some files for a client, or something else.",
};

export const CLIENT_TASKS: TaskTypeInfo[] = [
  CLIENT_INTAKE_TASK,
  CLIENT_ACCOUNT_CONFIRMATION_TASK,
  CLIENT_ONBOARDING_TASK,
  CLIENT_FILE_UPLOAD_TASK,
  CLIENT_PRE_REVIEW_CHECK_IN_TASK,
];

export const INTERNAL_TASKS: TaskTypeInfo[] = [
  MONEY_MOVEMENT_TASK,
  ACCOUNT_MAINTENANCE_TASK,
  INTERNAL_CLIENT_INFO_CHANGE_TASK,
  FINNANCIAL_PLANNING_TASK,
  INVESTMENT_MANAGEMENT_DIRECT_INDEXING_TASK,
  INVESTMENT_MANAGEMENT_SWIM_TASK,
  INTERNAL_CLIENT_REVIEW_PRESENTATION_TASK,
  INVESTMENT_PROPOSAL_PRESENTATION_TASK,
  INTERNAL_OTHER_TASK,
];

const taskSubCategoryMapping: Partial<
  Record<XLR8CS__Sub_Category_Task__c, string | null>
> = {
  [XLR8CS__Sub_Category_Task__c.ClientIntake]: "/welcome",
  [XLR8CS__Sub_Category_Task__c.ClientOnboarding]: "/onboarding",
  [XLR8CS__Sub_Category_Task__c.ClientPreReviewCheckIn]: "/checkin",
  [XLR8CS__Sub_Category_Task__c.ClientAccountConfirmation]: "/account-setup",
};

// This is a Proxy object that maps a task sub category to a guided flow URL. Defaults to null if no mapping is found or bad input.
export const TASK_SUB_CATEGORY_TO_GUIDED_FLOW_URL = new Proxy(
  taskSubCategoryMapping,
  {
    get: (target, prop: PropertyKey) => {
      if (
        typeof prop === "string" &&
        Object.values(XLR8CS__Sub_Category_Task__c).includes(
          prop as XLR8CS__Sub_Category_Task__c
        )
      ) {
        return target[prop as XLR8CS__Sub_Category_Task__c] ?? null;
      }
      return null;
    },
  }
);
